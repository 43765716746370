<template>
  <div>
    <div>      
        <b-card title="">
          <div class="mb-1">
            <router-link
              :to="`/administrador`"
              class="link-none"
            >
              <feather-icon icon="ArrowLeftIcon" size="20" class="" />
            </router-link>
          </div>
        <p>Eventos:</p>
        <b-form action="">
          <add-new-event
            :is-add-new-event-sidebar-active.sync="isAddNewEventSidebarActive"
            @close="isAddNewEventSidebarActive = false"
          />
          <div class="d-flex justify-content-end mb-2">
            <b-form-input
              v-model="searchValue"
              class="d-inline-block mr-1"
              placeholder="Filtrar..."
              @keyup="filtrarEventos"
            />
            <b-button
                  variant="primary"
                  @click="isAddNewEventSidebarActive = true"
            >
              <span class="text-nowrap">Adicionar evento</span>
            </b-button>
          </div>
        </b-form>
        <div class="grid ">
          <div class="grid-item img-event d-flex flex-column justify-content-end" style="max-width: 450px; height: 250px;" v-for="evento in eventos" :key="evento.id">
            <router-link :to="{ name: 'event_conf', params: { id: evento.id}}">
              <img
                style=" object-fit: cover;  height: 200px;"
                class="rounded w-100  border border-primary "
                :src="BaseUrl + '/storage/images/event_logo/' + evento.logo"
                :alt="evento.title"
              />
              <h3>{{ evento.title }}</h3>
            </router-link>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from '@vue/composition-api'
import AddNewEvent from "./AddNewEvent.vue"
export default {
  components: {
    AddNewEvent,
  },
  setup() {
    const isAddNewEventSidebarActive = ref(false)
    return {
      isAddNewEventSidebarActive
    }
  },
  data() {
    return {
      eventos: [],
      BaseUrl: process.env.VUE_APP_API_BASE,
      searchValue: null,
    };
  },
  beforeMount() {
    this.fetchEventos();
  },
  created() {
    
  },
  watch: {
    isAddNewEventSidebarActive(value) {
      if (!value) {
        this.fetchEventos();
      }
    },
  },
  methods: {
    filtrarEventos() {
      if (this.searchValue && this.searchValue.trim() !== '') {
        const termoPesquisa = this.searchValue.trim().toLowerCase();
        this.eventos = this.eventos.filter(evento => evento.title.toLowerCase().includes(termoPesquisa));
      } else {
        this.fetchEventos();
      }
    },
    fetchEventos() {
      const apiUrl = process.env.VUE_APP_API + "/events/get-events";
      axios
        .get(apiUrl)
        .then((response) => {
          this.eventos = response.data.events;
          //console.log(this.eventos)
        })
        .catch((error) => {
          console.error("Error fetching eventos:", error);
        });
    },
  },
};
</script>

<style scoped>
.img-event {
  border-color: #161D31 !important;
  border-width: 2px !important;
  margin-bottom: 10px;
}

.link-none {
  text-decoration: none !important;
  color: inherit;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.bg-dark-blue {
  background-color: #161d31 !important;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>